<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isContentVisible?: boolean
  }>(),
  {
    isContentVisible: false,
  }
)
</script>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.isContentVisible ? 'rotate-180 transform' : ''"
  >
    <path
      d="M12.7666 5.43827C12.5713 5.24301 12.2547 5.24301 12.0595 5.43827L7.99365 9.50408L3.92777 5.43819C3.73251 5.24293 3.41593 5.24293 3.22067 5.43819L2.86711 5.79175L7.64004 10.5648C7.8353 10.7601 8.15188 10.7601 8.34715 10.5648L13.1201 5.79182L12.7666 5.43827Z"
      fill="#292929"
    />
  </svg>
</template>
